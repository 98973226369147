import React from "react";
import { DepositAndWithdrawalQuery } from "../../prismic/staticQueries";
import { connect, useSelector } from "react-redux";
import { Link } from "gatsby";
import { Table as BTable } from "react-bootstrap";
import Layout from "../../layout";
import { BreadCrumbBanner } from "../../components/common";
import BANNER from "../../images/siteImages/depositAndWithdraw/DEPOSIT_WITHDRAW_BANNER.jpg";
import MC_VISA from "../../images/siteImages/depositAndWithdraw/MC_VISA.png";
import CRYPTO from "../../images/siteImages/depositAndWithdraw/CRYPTO.png";
import LOCAL_BANK from "../../images/siteImages/depositAndWithdraw/LOCAL_BANK.png";
import GPAY from "../../images/siteImages/depositAndWithdraw/GPAY.png";
import UPI from "../../images/siteImages/depositAndWithdraw/UPI.png";
import PAYTM from "../../images/siteImages/depositAndWithdraw/PAYTM.png";
import PaymentSlider from "../Company/PaymentSlider";

// const DEPOSIT_IMAGES = [
//   WIRED_BOX,
//   MC_VISA,
//   WIRE_TRANSFER,
//   BITPAY,
//   CRYPTO,
//   PERFECT_MONEY,
//   THUNDERX,
//   WALAOPAY,
//   BTBPAY,
// ];

const DEPOSIT_IMAGES = [LOCAL_BANK, UPI, MC_VISA, GPAY, PAYTM, CRYPTO];

// const WITHDRAW_IMAGES = [WIRE_TRANSFER, CASHU, PERFECT_MONEY, WALAOPAY, BTBPAY];
const WITHDRAW_IMAGES = [LOCAL_BANK, CRYPTO];
const OurMission = () => {
  const language = useSelector((state) => state.language);
  const depositWithdrawalData = DepositAndWithdrawalQuery(language);

  const {
    banner_image,
    d_headers,
    deposit_label,
    deposit_table,
    page_heading,
    w_headers,
    withdrawal_label,
    withdrawal_table,
  } = depositWithdrawalData;

  return (
    <>
      <Layout>
        <BreadCrumbBanner heading={page_heading} banner_image={BANNER} />
        <section className="job-contents p-top-20 p-bottom-65 deposit-withdrawal">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <h3 className="text-center heading">
                  {deposit_label && deposit_label.text}
                </h3>
              </div>
            </div>
          </div>
        </section>
        <section className="job-contents p-top-20 p-bottom-65 deposit-withdrawal">
          <div className="container">
            <div className="tables-responsive text-center">
              <BTable responsive="md" className="payments">
                <thead className="table-secondary table-secondary--darken">
                  <tr>
                    {d_headers.length > 0 &&
                      d_headers.map((item, index) => (
                        <th key={index}>{item.d_header_title.text}</th>
                      ))}
                  </tr>
                </thead>
                <tbody>
                  {deposit_table.length > 0 &&
                    deposit_table.map((row, index) => (
                      <tr key={index}>
                        <td>
                          <img
                            src={DEPOSIT_IMAGES[index]}
                            alt={row.d_currency.text}
                          />
                        </td>
                        <td>{row.d_currency.text}</td>
                        <td>{row.d_fee.text}</td>
                        <td>{row.d_processing_time.text}</td>

                        <td>
                          <Link
                            className="btn btn-secondary"
                            to={row.d_action_link.text}
                          >
                            {row.d_action_label.text}
                          </Link>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </BTable>
            </div>
          </div>
        </section>

        <section className="job-contents p-top-20 p-bottom-65 deposit-withdrawal">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <h4 className="text-center heading">{withdrawal_label.text}</h4>
              </div>
            </div>
          </div>
        </section>
        <section className="job-contents p-top-20 p-bottom-65 deposit-withdrawal">
          <div className="container">
            <div className="tables-responsive text-center">
              <BTable responsive="md" className="payments">
                <thead className="table-secondary table-secondary--darken">
                  <tr>
                    {w_headers.map((item, index) => (
                      <th key={index}>{item.w_header_title.text}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {withdrawal_table.map((row, index) => (
                    <tr key={index}>
                      <td>
                        <img
                          src={WITHDRAW_IMAGES[index]}
                          alt={row.w_currency.text}
                        />
                      </td>
                      <td>{row.w_currency.text}</td>
                      <td>{row.w_fee.text}</td>
                      <td>{row.w_processing_time.text}</td>

                      <td>
                        <Link
                          className="btn btn-secondary"
                          to={
                            row.w_action_link.text + "?type=" + row.w_type.text
                          }
                          state={{ type: row.w_type.text }}
                        >
                          {row.w_action_label.text}
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </BTable>
            </div>
          </div>
        </section>
        
      </Layout>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    language: state.language,
  };
};

export default connect(mapStateToProps)(OurMission);
